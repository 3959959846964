.formWrapper {
  width: 500px;
  min-height: 366px;
  background: #ffffff;
  border-radius: 4px;
  padding: 32px;

  :global {
    .ant-form-item-label > label {
      font-size: 12px;
      color: #242424;
    }
    .ant-input-affix-wrapper-lg {
      padding: 10.5px;
      font-size: 14px;
      .ant-input-prefix {
        margin-right: 8px;
      }
    }
  }
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #242424;
  margin-bottom: 24px;
}
.forgotPassword {
  float: right;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #002637;
  &:hover {
    color: #002637;
    opacity: 0.7;
  }
}

.button {
  width: 100%;
  height: 48px;
}
