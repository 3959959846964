.footer {
  background: #022230;
  &_bottom {
    margin-top: 24px;
  }
}

.titles {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  p {
    margin: 0px;
  }
  .link {
    color: #ffffff !important;
    &:hover {
      color: #ffffff;
      opacity: 0.7;
    }
  }
}
.textWrapper {
  display: flex;
  justify-content: center;
  svg {
    //margin-top: 2px;
    margin-right: 4px;
    min-width: 16px;
    min-height: 16px;
  }
}
.links {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  color: #777777;
  a {
    color: #777777;
    &:hover {
      opacity: 0.7;
      color: #777777;
    }
  }
}
