.button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
  border-radius: 4px;
  min-height: 32px;
  height: auto;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
    color: #fff;
  }
}

.primary {
  background-color: #002637;
  &:active,
  &:hover,
  &:focus {
    background-color: #002637;
    color: #ffffff;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #002637;
    color: #ffffff;
    opacity: 0.7;
  }
}

.secondary {
  background-color: #e8c199;
  &:active,
  &:hover,
  &:focus {
    background-color: #e8c199;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #e8c199;
    opacity: 0.7;
  }
}

.grey {
  color: #002637;
  background-color: #f6f6f6;
  &:active,
  &:hover,
  &:focus {
    background-color: #f6f6f6;
    color: #002637;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #f6f6f6;
    color: #002637;
    opacity: 0.7;
  }
}

.green {
  background-color: #42b794;
  &:active,
  &:hover,
  &:focus {
    background-color: #42b794;
    color: #ffffff;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #42b794;
    color: #ffffff;
    opacity: 0.7;
  }
}

.red {
  background-color: #e56a6a;
  &:active,
  &:hover,
  &:focus {
    background-color: #e56a6a;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #e56a6a;
    opacity: 0.7;
  }
}

.primaryWhite {
  background-color: #ffffff;
  border: 1px solid #002637;
  color: #002637;
  &:active,
  &:hover,
  &:focus {
    background-color: #ffffff;
    color: #002637;
    border: 1px solid #002637;
  }
  &:disabled,
  &:disabled:hover {
    background-color: #ffffff;
    color: #002637;
    border: 1px solid #002637;
    opacity: 0.7;
  }
}

.big {
  height: 48px;
  min-width: 48px;
}

.iconMargin {
  svg {
    margin-right: 8px;
  }
}

.upperCase {
  text-transform: uppercase;
}
