.header {
  padding: 18px 25px 15px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1c1c1c;
}

.rightSide {
  display: flex;
  align-items: center;
}
