.content {
  padding: 24px;
  :global {
    .ant-form-item-required,
    .ant-form-item-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #1c1c1c;
      &:before {
        display: none !important;
      }
    }
  }
}
